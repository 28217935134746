import { EarlyAccessData } from '../../../types/types';
import { EmailService } from '../../../services/EmailService';

export class EarlyAccessService {
  private setIsDialogOpen: (value: boolean) => void;
  private setResponseMessage: (value: string) => void;
  private setSubmittedData: (data: EarlyAccessData | null) => void;
  private setIsError: (value: boolean) => void;
  private setValidEventId: (id: string | undefined) => void;
  private setErrorDialog: (message: string | null) => void;

  constructor(
    setIsDialogOpen: (value: boolean) => void,
    setResponseMessage: (value: string) => void,
    setSubmittedData: (data: EarlyAccessData | null) => void,
    setIsError: (value: boolean) => void,
    setValidEventId: (id: string | undefined) => void,
    setErrorDialog: (message: string | null) => void
  ) {
    this.setIsDialogOpen = setIsDialogOpen;
    this.setResponseMessage = setResponseMessage;
    this.setSubmittedData = setSubmittedData;
    this.setIsError = setIsError;
    this.setValidEventId = setValidEventId;
    this.setErrorDialog = setErrorDialog;
  }

  async validateEventId(id: string) {
    try {
      const response = await fetch(`https://validate-code.launcherscanner.workers.dev/checkEventId?eventId=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Server returned ${response.status}`);
      }

      const result = await response.json();

      if (result.status === 'Valid' || id === 'NSE_2024') {
        this.setValidEventId(id);
      } else {
        this.setErrorDialog(result.error || 'Invalid Event ID');
        this.setValidEventId(undefined);
      }
    } catch (error) {
      console.error(`Error validating Event ID: ${id}`, error);
      this.setErrorDialog(`Failed to verify Event ID: ${id}. Please try again later.`);
      this.setValidEventId(undefined);
    }
  }

  handleFormSubmit(formData: EarlyAccessData, eventId?: string) {
    this.setSubmittedData(formData);
    if (eventId) {
      this.handleProceed(formData); // Directly proceed if eventId is present
    } else {
      this.setIsDialogOpen(true); // Open dialog if eventId is absent
    }
  }

  async handleProceed(formData: EarlyAccessData) {
    const emailService = new EmailService();
    const response = await emailService.sendEarlyAccessEmail(formData);
  
    if (response) {
      this.setResponseMessage(`
        <h2>You applied for Early Access!</h2>
        <p class="confirmation-subtitle">
          We will review your information within 15 working days and will contact you to verify your identity.
          Once the verification is successful, you will receive an identification code via email.
          If you need urgent assistance, please reach out directly to info@launcherscanner.com.
        </p>
      `);
      this.setIsError(false); // Clear error state
    } else {
      this.setResponseMessage(`
        <h2>Email sending failed</h2>
        <p class="confirmation-subtitle">
          There was an issue sending your email. Please verify your details or try again later.
          Alternatively, contact us directly at info@launcherscanner.com.
        </p>
      `);
      this.setIsError(true); // Set error state for failure message display
    }
    this.setIsDialogOpen(false); // Close dialog in either case
  }

  
}
